import React from 'react';

export type NutritionalInfoType = {
  calories: number;
  protein: number;
  carbs: number;
  fats: number;
};

export type MacrosContainerType = {
  nutritionalInfo: NutritionalInfoType;
  size?: string;
  rounded?: boolean;
};

export const MacrosContainer: React.FC<MacrosContainerType> = ({
  nutritionalInfo,
  size,
  rounded = false,
}) => {
  const containerStyles = size === 'small' ? '' : '';
  const styles = size === 'small' ? 'px-1' : 'md:px-2';
  const pl = size === 'small' ? 'pl-[3px]' : 'pl-1';
  const mb = size === 'nomargin' ? '' : '';
  const roundedStyle = rounded ? 'rounded-2xl ' : '';

  return (
    <div
      className={`flex h-6 w-full items-center justify-evenly bg-black font-interSemibold text-white ${roundedStyle} ${containerStyles} ${mb} px-2.5 py-1.5`}
    >
      <span className={`px-1 text-xxs md:px-0.5 md:text-xs lg:px-1 ${styles}`}>
        {nutritionalInfo?.calories?.toFixed(0)}
        <span className={`${pl} font-inter`}>CAL</span>
      </span>
      <span className={`px-1 text-xxs md:px-0.5 md:text-xs lg:px-1 ${styles}`}>
        {nutritionalInfo?.protein?.toFixed(0)}
        <span className={`${pl} font-inter`}>P</span>
      </span>
      <span className={`px-1 text-xxs md:px-0.5 md:text-xs lg:px-1 ${styles}`}>
        {nutritionalInfo?.carbs?.toFixed(0)}
        <span className={`${pl} font-inter`}>C</span>
      </span>
      <span className={`px-1 text-xxs md:px-0.5 md:text-xs lg:px-1 ${styles}`}>
        {nutritionalInfo?.fats?.toFixed(0)}
        <span className={`${pl} font-inter`}>F</span>
      </span>
    </div>
  );
};
