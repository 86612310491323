import { observer } from 'mobx-react-lite';
import Image from 'next/image'; // Import the Image component
import { useRouter } from 'next/router';

import { useStore } from '@/models/root-store';
import { formatLikeCount } from '@/utils/track/format-helpers';

import styles from './ProductLikePill.module.css';

interface ProductLikePillProp {
  view: string;
  sku: string;
  selected?: boolean;
}

const ProductLikePill: React.FC<ProductLikePillProp> = observer(
  ({ view, sku }) => {
    const {
      productStore,
      customerStore: { isProductLiked },
    } = useStore();

    const liked = isProductLiked(sku);
    const router = useRouter();
    let path = router?.asPath ? router.asPath.split('?')[0] : '';

    // will need more paths later
    if (path?.includes('product')) {
      path = 'PDP';
    } else if (path?.includes('menu')) {
      path = 'Menu';
    } else if (path?.includes('account')) {
      path = 'Account';
    } else {
      path = 'Goal Based';
    }
    const product = productStore.productsLookup[sku];

    const pillClass =
      view === 'small'
        ? 'h-[22px] md:h-[24px] py-1'
        : 'h-[24px] md:h-[32px] py-1.5 px-3 bg-off-grey rounded-[23px]';
    const textClass = view === 'small' ? 'text-xs' : 'text-xs md:text-base';

    const likeCount = formatLikeCount(product?.likes ?? 0);

    const imgWidth =
      view === 'large' ? 'h-[16px] md:h-[18px]' : 'h-[14px] md:h-[16px]';
    const imgHeight =
      view === 'large' ? 'w-[16px] md:w-[18px]' : 'w-[14px] md:w-[16px]';
    const spacing =
      view === 'large' ? 'space-x-[6px] md:space-x-[8px]' : 'space-x-[6px]';

    return (
      <div
        className={`flex items-center justify-center ${spacing} ${pillClass} ${
          liked ? styles.iconLiked : ''
        } ${liked ? styles.resizeIcon : ''}`}
      >
        <div className={`${styles.imageContainer} ${imgWidth} ${imgHeight}`}>
          <Image
            src={'https://www.datocms-assets.com/81001/1705291559-thumb.svg'}
            alt="icon"
            fill={true}
            className={`${styles.thumb} max-h-[18px] max-w-[18px]`}
          />
        </div>
        <div className="flex justify-center">
          <span
            className={`${textClass} text-nowrap font-interMedium text-[#686868]`}
          >
            {likeCount || ''} Likes
          </span>
        </div>
      </div>
    );
  },
);

export default ProductLikePill;
