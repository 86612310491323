import { useAuth } from '@clerk/nextjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import type { ProductModelType } from '@/models/api';
import { useStore } from '@/models/root-store';
import { AUTH_NAVIGATION_TARGET } from '@/shared/constant';
import {
  trackProductLiked,
  trackProductUnliked,
} from '@/utils/track/tracker.helper';

import styles from './styles.module.css';

export type LikeIconType = {
  product: ProductModelType;
};

export const LikeIcon: React.FC<LikeIconType> = observer(({ product }) => {
  const {
    productStore,
    customerStore: { isProductLiked },
  } = useStore();
  const { isSignedIn: isLoggedIn } = useAuth();
  const router = useRouter();

  const liked = isProductLiked(product.sku);

  const [animation, setAnimation] = useState('');
  const debouncedPostProductLike = useCallback(
    _.debounce(() => productStore.postProductLikes(!liked, product.sku), 500),
    [product.sku, liked],
  );

  const handleButtonClick = async () => {
    if (!isLoggedIn) {
      sessionStorage.setItem(AUTH_NAVIGATION_TARGET, window.location.pathname);
      router.push('/signin');
      return;
    }

    setAnimation(liked ? '' : styles.liked!);
    // snowplow tracking for product like
    try {
      if (!liked) {
        trackProductLiked(product);
      } else {
        trackProductUnliked(product);
      }
    } catch (error) {
      // ignore error
    }
    debouncedPostProductLike();
  };

  return (
    <div
      className="absolute right-2.5 top-2.5 z-20"
      role="button"
      onClick={handleButtonClick}
    >
      <Image
        src={
          liked
            ? 'https://www.datocms-assets.com/81001/1705288485-dislike.png'
            : 'https://www.datocms-assets.com/81001/1705288492-like.png'
        }
        width="26"
        height="26"
        className={` ${styles.thumb} ${animation}`}
        alt={'like'}
      />
    </div>
  );
});
