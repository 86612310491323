type SizeClassType = {
  large: string;
  small: string;
};

export type PackAndDiscountType = {
  promotionTotal: number | undefined;
  grossPrice: number | undefined;
  size?: keyof SizeClassType;
  packs?: number;
  menu?: boolean;
  quantityTag?: string;
};

export const PackAndDiscount: React.FC<PackAndDiscountType> = ({
  promotionTotal,
  grossPrice,
  size = 'small',
  packs = 0,
  menu = false,
  quantityTag = '',
}) => {
  // CALCULATE DISCOUNT FOR PROMOTIONS
  const discount =
    promotionTotal && grossPrice
      ? Math.trunc((promotionTotal / grossPrice) * 100)
      : 0;

  if (discount === 0) return null;

  const sizeClasses: SizeClassType = {
    large: 'h-11 text-sm md:text-xl rounded-none md:rounded-b-xl',
    small: 'h-6 text-xxs md:text-xs',
  };

  const sizeClass = sizeClasses[size];
  const style = menu ? 'w-full' : 'w-fit rounded-[48px] text-xxs px-2 h-5';

  const quanityText = quantityTag || `${packs} Pack`;

  return (
    <div
      className={`${style} ${sizeClass} flex items-center justify-center bg-black py-1 font-interSemibold leading-3 md:py-1.5`}
    >
      {packs > 0 && <div className="pl-1.5 text-white">{quanityText}</div>}
      <div className="pl-2 text-grey-dark">|</div>
      <div className={`w-fit pl-2 pr-1.5 text-lime`}>
        Save {discount.toFixed(0)}%
      </div>
    </div>
  );
};
